<template>
    <!-- <video autoplay muted loop id="backgroundVideo">
        <source src="@/assets/backgroundVideo.mp4" type="video/mp4">
    </video> -->
    <div style="position: relative;">
        <Nav />
        <router-view />
    </div>
</template>

<script>
import Nav from '@/components/Nav';

export default {
    components: {
        Nav
    }
}
</script>
