import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import locales from '@/language.js';
import './assets/css/style.scss';

import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

library.add(faEdit, faTrash);

i18next.init({
  	lng: 'en',
  	resources: {
    	en: { translation: locales.en },
    	fr: { translation: locales.fr }
  	},
});

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(I18NextVue, {i18next}).use(store).use(router);
app.use(ToastPlugin);

app.config.globalProperties.BACKGROUND_VIDEO = process.env.VUE_APP_BACKGROUND_VIDEO;
app.config.globalProperties.BACKGROUND_chat_VIDEO = process.env.VUE_APP_BACKGROUND_chat_VIDEO;
app.config.globalProperties.BACKGROUND_embed_VIDEO = process.env.VUE_APP_BACKGROUND_embed_VIDEO;
app.config.globalProperties.PROJECT_SHOW = !+process.env.VUE_APP_PROJECT_HIDDEN
app.config.globalProperties.PROMPT_FOR_ANSWERS_SHOW = !!process.env.VUE_APP_PROMPT_FOR_ANSWERS_SHOW
app.config.globalProperties.UPLOADING_FAQ_SHOW = !+process.env.VUE_APP_UPLOADING_FAQ_DATA_HIDDEN
app.config.globalProperties.LANGUAGE_CHANGE_SHOW = !+process.env.VUE_APP_CHANGE_LANGUAGE_HIDDEN
app.config.globalProperties.PROMPT_LABEL_NAME = process.env.VUE_APP_PROMPT_LABEL_NAME || 'prompt_prefix';
app.config.globalProperties.PROMPT_FOR_ANSWERS_LABEL_NAME = process.env.VUE_APP_PROMPT_FOR_ANSWERS_LABEL_NAME || 'prompt_for_answers';

app.mount('#app')
