<template>
    <div class="page-train">
        <div class="center">
            <lang v-if="LANGUAGE_CHANGE_SHOW" />

            <div class="success message" v-if="message">
                <div class="item" v-for="(item, index) in message" :key="index">
                    <span class="close" @click="closeMessage(index)">x</span>
                    <div>
                        <span>{{ $t("questions") }}:</span>&nbsp;
                        <i v-for="(question, index) in item.questions" :key="index">
                            {{ question }}&nbsp;
                        </i>
                    </div>
                    <div>
                        <span>{{ $t("answer") }}:</span>&nbsp;
                        <i>{{ item.answer }}</i>
                    </div>
                </div>
            </div>

            <div class="error message" v-if="status">
                <div class="item">
                    <span class="close" @click="status = false">x</span>
                    <div>{{ $t("error") }}!</div>
                </div>
            </div>
            <form action="#" class="form" ref="form" @submit.prevent="updatePrePrompt">
                <label class="field">
                    <div class="text">{{ $t(PROMPT_LABEL_NAME) }}</div>
                    <textarea style="min-height: 320px;" :placeholder="$t('text')" v-model.trim="prompt_prefix"></textarea>
                </label>

                <label class="field" v-if="PROMPT_FOR_ANSWERS_SHOW">
                    <div class="text">{{ $t(PROMPT_FOR_ANSWERS_LABEL_NAME) }}</div>
                    <Textarea style=" min-height: auto;" :placeholder="$t('text')"
                        v-model="prompt_answer_pre_prefix"></Textarea>
                </label>

                <label class="field field-file border-red" :style="[PROJECT_SHOW ? {} : { 'display': 'none' }]">
                    <div class="text">{{ $t("Project: ") }}</div>
                    <ListOfProjects :allowEmpty="true" v-model="form.project_id" s></ListOfProjects>
                </label>

                <label v-if="PROJECT_SHOW" class="field">
                    <div class="text">{{ $t('prompt_for_project_prefix') }}</div>
                    <textarea style="min-height: 320px;" :placeholder="$t('text')"
                        v-model.trim="project_prompt_prefix"></textarea>
                </label>

                <div class="tx-c mt15">
                    <input type="submit" :value="$t('Save prompts')" ref="submit" class="btn">
                </div>
            </form>
            <form v-if="UPLOADING_FAQ_SHOW" action="#" class="form" ref="form" @submit.prevent="sendForm">
                <label class="field">
                    <div style="display: flex;">
                        <div class="text">{{ $t("data") }}</div>
                        <button @click.prevent="clearTraining()" class="ml-auto"
                            style="margin-bottom: 12px; margin-left: auto;font-size: larger; cursor: pointer;">
                            <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                        </button>
                    </div>
                    <textarea :placeholder="`Question1?
Answer1

Question2?
Answer2`" v-model.trim="form.text"></textarea>
                </label>

                <div class="tx-c mt15">
                    <input type="submit" :value="$t('train')" ref="submit" class="btn">
                </div>

                <label class="field field-file">
                    <div style="display: flex; gap: 10px;">
                        <div class="text">{{ $t("file") }}</div><a style="color: blue;" href="/Sample.csv">Sample.csv</a>
                    </div>
                    <input type="file" name="file" ref="file" accept=".txt, .docx, .mkdn, .csv" @change="chooseFile">
                    <div class="file">
                        <div class="button">
                            <img src="@/assets/img/ic-download.svg" alt="">
                            <span>{{ $t("upload") }}</span>
                        </div>
                        <div class="name" v-html="nameFile"></div>
                    </div>
                </label>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import lang from '@/components/LangControl';
import ListOfProjects from '@/components/Projects/ListOfProjects.vue';
import Textarea from '@/components/Textarea.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    components: {
        lang,
        ListOfProjects,
        Textarea,
        FontAwesomeIcon
    },
    data() {
        return {
            form: {
                file: null,
                text: '',
                project_id: 0,
            },
            nameFile: 'FAQ File<br> txt, docx, mkdn, csv.',
            message: null,
            status: false,
            prompt_answer_pre_prefix: null,
            prompt_prefix: null,
            project_prompt_prefix: null,
        }
    },
    created() {
        this.$store.dispatch('updateBotPreprompt');
        if (this.getcurrent_bot_preprompt) {
            this.prompt_answer_pre_prefix = this.getcurrent_bot_preprompt;
        }
        if (this.getcurrent_bot_prompt_prefix) {
            this.prompt_prefix = this.getcurrent_bot_prompt_prefix;
        }

        if (this.form.project_id) {
            this.$store.dispatch('updateProjectTrainingData', { project_id: this.form.project_id });
        }
    },
    computed: {
        getcurrent_bot_preprompt() {
            return this.$store.getters.getcurrent_bot_preprompt;
        },
        getcurrent_bot_prompt_prefix() {
            return this.$store.getters.getcurrent_bot_prompt_prefix;
        },
        savedTrainingData() {
            return this.form.project_id && this.$store.getters.getProjectsTrainingData(this.form.project_id) || '';
        },
        projectsList() {
            return Object.fromEntries(this.$store.getters.getAvailableProjects.map(v => ([v.id, v])));
        }
    },
    watch: {
        form: {
            handler(newValue, oldValue) {
                if (newValue.project_id) {
                    this.$store.dispatch('updateProjectTrainingData', { project_id: newValue.project_id });
                    this.$store.dispatch('updateAvailableProjects')
                        .then(() => {
                            if (!newValue.project_id) {
                                this.project_prompt_prefix = null;
                                return;
                            }
                            this.project_prompt_prefix = this.projectsList[newValue.project_id].prompt_prefix;
                        })
                }
            },
            deep: true
        },
        savedTrainingData() {
            this.form.text = this.savedTrainingData;
        },
        getcurrent_bot_preprompt() {
            this.prompt_answer_pre_prefix = this.getcurrent_bot_preprompt;
        },
        getcurrent_bot_prompt_prefix() {
            this.prompt_prefix = this.getcurrent_bot_prompt_prefix;
        }
    },
    methods: {
        async chooseFile(e) {
            this.nameFile = e?.target?.files?.[0]?.name ?? null;

            await this.sendForm();
        },
        closeMessage(index) {
            this.message.splice(index, 1);
        },
        updatePrePrompt() {
            if (this.project_prompt_prefix && this.project_prompt_prefix.length && !this.form.project_id) {
                this.$toast.error(`Please select Project to save project prompt`, { position: "top" });
                return;
            }
            const url = API_URL + '/api/update-bot-prompt';
            let data = JSON.stringify({ id: API_BOT_ID, prompt_answer_pre_prefix: this.prompt_answer_pre_prefix, prompt_prefix: this.prompt_prefix });
            let headers = { 'Content-Type': 'application/json' }
            try {
                axios({ url: url, data: data, method: "POST", headers: headers })
                    .then(result => {
                        if (result.data.status) {
                            this.$toast.success('Your prompts have been saved', { position: "top" });
                        } else {
                            this.$toast.error('Error');
                            alert(JSON.stringify(result.data));
                        }
                    });
                axios.post(API_URL + '/local-intents-responses-storage/projects/update', {
                    prompt_prefix: this.project_prompt_prefix,
                    id: this.form.project_id,
                    bot_id: API_BOT_ID,
                }).then(() => {
                    this.$store.dispatch('updateAvailableProjects');
                })
            } catch (error) {
                this.status = true;
                console.log(error);

                this.$toast.error('Error');
                alert(JSON.stringify(error));
            }
        },
        clearTraining() {
            if (!this.form.project_id) {
                this.$toast.error(`Please select Project`, { position: "top" });
                return;
            }
            if (!window.confirm(`Are you sure you want to erase current Questions and Answers?`)) {
                return;
            }
            try {
                axios({ url: API_URL + '/api/train?bot_id=' + API_BOT_ID + '&project_id=' + this.form.project_id, method: "DELETE" })
                    .then(result => {
                        if (result.data.status) {
                            this.$store.dispatch('updateProjectTrainingData', { project_id: this.form.project_id });
                        } else {
                        }
                    });
            } catch (error) {
                console.error(error);
            }
        },
        sendForm() {
            if (!this.form.project_id) {
                this.$toast.error(`Please select Project`, { position: "top" });
                return;
            }
            if (!this.form.text && !this.$refs.file.files[0]) {
                this.$toast.error('FAQ data is required', { position: "top" });
                return;
            }
            if (this.savedTrainingData.length) {
                if (!window.confirm(`The current project already contains Questions & Answers. 

Uploading a new file will erase them.

Are you sure you want to upload the file?`)) {
                    return;
                }
            }
            this.$refs.submit.classList.add('preloader');
            const url = API_URL + '/api/train?bot_id=' + API_BOT_ID + '&project_id=' + this.form.project_id;
            let data = new FormData(this.$refs.form);
            let headers = { 'enctype': 'multipart/form-data' }

            if (this.form.text.length && !this.$refs.file.files[0]) {
                data = JSON.stringify({ raw: this.form.text });
                headers = { 'Content-Type': 'application/json' }
            }

            try {
                this.$refs.file.value = null;
                this.nameFile = 'FAQ File<br> txt, docx, mkdn, csv.';
            } catch (ex) {
                console.error(ex)
            }

            try {
                axios({ url: url, data: data, method: "POST", headers: headers })
                    .then(result => {
                        if (result.data.status) {
                            this.$refs.submit.classList.remove('preloader');
                            this.$refs.form.reset();
                            this.message = result.data.data.rows;

                            this.$store.dispatch('updateProjectTrainingData', { project_id: this.form.project_id });
                        } else {
                            this.status = true;
                        }
                    });
            } catch (error) {
                this.status = true;
                console.log(error);
            }

        }
    }
}
</script>

<style lang="scss">
.border-red {
    border: red 2px solid;
    padding: 2px;
    margin: 2px;
}

.message {
    position: fixed;
    right: -300px;
    bottom: 15px;
    max-width: 300px;
    width: 100%;
    animation: right 0.5s forwards;

    .item {
        background: rgba(0, 0, 0, .8);
        padding: 10px;
        border-radius: 12px;
        border: 1px solid var(--colAkcent);
        font-size: 14px;
        color: #fff;
        position: relative;

        div+div {
            margin-top: 5px;
        }

        &+.item {
            margin-top: 10px;
        }
    }

    .item .close {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        color: #fff;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            color: var(--colAkcent);
        }
    }

    &.error {
        .item {
            border-color: red;
            color: red;
        }

        .item .close {
            color: red;

            &:hover {
                color: var(--colAkcent);
            }
        }
    }
}

@keyframes right {
    0% {
        right: -300px;
    }

    100% {
        right: 15px;
    }
}

.page-train {
    // background: url(../assets/img/bg-train.jpeg) no-repeat center top;
    background-size: cover;
    min-height: 100%;

    padding: 140px 0 75px 0;

    .center {
        max-width: 600px;
    }

    .btn {
        &.preloader {
            pointer-events: none;
            background: #fff url(../assets/img/preloader.svg) no-repeat center;
            color: transparent;
        }
    }
}
</style>
