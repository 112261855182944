<template>
	<nav class="nav">
        <router-link :to="{name: 'home'}" class="btn" active-class="active">{{ $t('train') }}</router-link>
        <router-link :to="{name: 'chat'}" class="btn" active-class="active">{{ $t('chat') }}</router-link>
        <router-link v-if="PROJECT_SHOW" :to="{name: 'projects'}" class="btn" active-class="active">{{ $t('projects') }}</router-link>
    </nav>
</template>

<script></script>

<style lang="scss">
	.nav {
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translateX(-50%);
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 0 20px;
	}
</style>