<template>
	<div class="page-chat">
		<div class="center">
			<lang v-if="LANGUAGE_CHANGE_SHOW" />

			<h1 v-if="noEmbed" class="title">{{ $t('chat') }}</h1>

			<label class="field field-file" v-if="PROJECT_SHOW && noEmbed">
				<div class="text">{{ $t("Select Project") }}</div>
				<ListOfProjects v-model="form.project_id"></ListOfProjects>
			</label>

			<div class="faq-results" v-if="questions.length">
				<div class="scroll" ref="scroll">
					<ul>
						<li v-for="(question, index) in questions" :key="index" :class="question.type">
							<div class="icon">
								<img src="@/assets/img/user.svg" alt="" v-if="question.type == 'question'">
								<img src="@/assets/img/crown.svg" alt="" v-else>
							</div>

							<div class="text" v-if="question.question">
								<p style="margin-bottom: 15px">Q: {{ question.question }}</p>
								<p>A:<br>
								<div v-html="replaceLinks(question.text)"></div>
								</p>
							</div>
							<div class="text" v-else>
								<p>
								<div v-html="replaceLinks(question.text)"></div>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<form action="#" class="faq-generate" ref="form" @submit.prevent="sendForm">
				<div style="display: flex; flex-direction: column; width: 100%;">
					<textarea type="text" name="prompt" class="prompt" v-model="form.text"
						:placeholder="$t('ask')"></textarea>
					<input type="hidden" name="bot_id" :value="API_BOT_ID">
					<input style="margin-left: auto;" type="submit" class="btn" :value="$t('generate')" ref="submit">
				</div>
			</form>

			<div v-if="noEmbed" class="tx-c mt15">
				<button class="btn-link" @click="showEmbed = !showEmbed">
					<span>{{ $t('addFaq') }}</span>
					<img src="@/assets/img/link.svg" alt="">
				</button>
			</div>

			<textarea @click="() => $refs.code.select()" ref="code"
				style="margin-top: 20px; border: 1px solid black; padding: 10px; min-width: 100%; min-height: 290px;"
				readonly v-if="showEmbed">
						{{ embedText }}
					</textarea>
		</div>
	</div>
</template>

<script>
const sc = 'script';

import axios from 'axios';
import lang from '@/components/LangControl';
import ListOfProjects from '@/components/Projects/ListOfProjects.vue';

export default {
	components: {
		lang,
		ListOfProjects
	},
	data() {
		return {
			form: {
				text: null,
				project_id: null,
			},
			showEmbed: false,
			questions: [],
			noEmbed: false,

			conversationId: null,
		}
	},
	created() {
		this.noEmbed = this.$route.name !== 'embed';

		if (this.$route.query.project_id) {
			this.form.project_id = this.$route.query.project_id;
		}
	},
	computed: {
		embedText() {
			return `
<!DOCTYPE html>
<html lang="">
  <head>
    <title>Embed example</title>
    <${sc} src="${location.origin}/embedder.js"></${sc}>
  </head>
  <body>
  </body>

  <${sc}>
    createChatWidget({project_id: ${this.form.project_id}});
  </${sc}>
</html>`;
		}
	},
	methods: {
		replaceLinks(text) {
			const Rexp = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;
			
			const linksReplaced = text.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");
			return linksReplaced.replace(/([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/ig, '<a href="mailto:$1">$1</a>');
		},
		async sendForm() {
			if (!this.form.text) {
				this.$toast.error('Message text is required!');
				return;
			}
			let question = {
				type: 'question',
				text: this.form.text
			}
			const url = API_URL + '/api/complete';
			let scrollBottom = 0;

			this.questions.push(question);
			this.$refs.submit.classList.add('preloader');

			if (this.$refs.scroll != null) {
				setTimeout(() => {
					scrollBottom = this.$refs.scroll.scrollHeight;
					this.$refs.scroll.scrollTo({
						top: scrollBottom,
						behavior: 'smooth'
					});
				}, 50);
			}

			try {
				axios.get(url, {
					params: {
						prompt: this.form.text,
						bot_id: API_BOT_ID,
						project_id: this.form.project_id,
						lang: this.$i18next.language.toUpperCase(),
						conversationId: this.conversationId,
					},
				}).then(result => {
					let question = {
						type: 'answer',
						text: result.data.data.answer,
						question: result.data.data.resultData.question,
					}

					this.conversationId = result.data.data.conversationId;

					this.questions.push(question);
					this.form.text = '';
					this.$refs.submit.classList.remove('preloader');

					if (this.$refs.scroll != null) {
						setTimeout(() => {
							scrollBottom = this.$refs.scroll.scrollHeight;
							this.$refs.scroll.scrollTo({
								top: scrollBottom,
								behavior: 'smooth'
							});
						}, 50);
					}
				});
			} catch (error) {
				console.log(error);
			}
		}
	}
}
</script>

<style lang="scss">
.page-chat {
	a {
		color: white;
		text-decoration: underline;
	}

	// background: url(../assets/img/bg-chat.jpeg) no-repeat center top;
	background-size: cover;
	min-height: 100%;
	padding: 140px 0 75px 0;

	.faq-results {
		border: 1px solid var(--colAkcent);
		border-radius: 12px;
		font-size: 16px;
		color: #FFFFFF;
		background: #000;
		overflow: hidden;

		ul {
			padding: 0 0;

			li {
				display: flex;
				align-items: flex-start;
				padding: 10px 20px;

				.icon {
					min-width: 45px;
					line-height: 0;
					text-align: center;
					margin-right: 15px;
				}

				&:nth-child(odd) {
					background: #2F2F2F;

					.text {
						padding-top: 5px;
					}
				}

				&:nth-child(even) {
					padding-top: 20px;
					padding-bottom: 20px;
				}
			}
		}
	}

	.faq-generate {
		// border: 1px solid var(--colAkcent);
		border-radius: 12px;
		// padding: 5px;
		margin-top: 17px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// background: var(--colBg);

		input[type="text"] {
			min-height: 40px;
			padding: 0 30px;
			outline: none;
			width: 100%;
			font-weight: 400;
			font-size: 16px;
			color: rgba(255, 255, 255, 0.5);

			&::placeholder {
				color: rgba(255, 255, 255, 0.5);
			}
		}

		.btn {
			min-width: 110px;
			border-radius: 10px;
			background: var(--colAkcent);
			padding-top: 10px;
			padding-bottom: 10px;
			color: white;
			width: fit-content;
			cursor: pointer;
			margin-top: 20px;

			&:hover {
				// background: #fff;
			}

			&.preloader {
				pointer-events: none;
				background: #fff url(../assets/img/preloader.svg) no-repeat center;
				color: transparent;
			}
		}
	}
}
</style>