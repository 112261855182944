import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    availableProjects: [],
    current_bot_data: null,
    projectsTrainingData: {},
  },
  getters: {
    getAvailableProjects: (state) => state.availableProjects,
    getcurrent_bot_preprompt: (state) => state.current_bot_data && state.current_bot_data.prompt_answer_pre_prefix || null,
    getcurrent_bot_prompt_prefix: (state) => state.current_bot_data && state.current_bot_data.prompt_prefix || null,
    getProjectsTrainingData: state => project_id => state.projectsTrainingData[project_id],
  },
  mutations: {
    SET_AVAILABLE_PROJECTS(state, list) {
      state.availableProjects = list;
    },
    SET_CURRENT_BOT_DATA(state, bot_data) {
      state.current_bot_data = bot_data;
    },
    SET_PROJECT_TRAINING_DATA(state, { project_id, data }) {
      state.projectsTrainingData[project_id] = data;
    }
  },
  actions: {
    updateAvailableProjects(context) {
      axios.get(API_URL + '/local-intents-responses-storage/projects?bot_id=' + API_BOT_ID)
        .then(result => {
          context.commit('SET_AVAILABLE_PROJECTS', result.data.list || []);
        });
    },
    updateBotPreprompt(context) {
      axios.get(API_URL + '/api/bot-prompt?bot_id=' + API_BOT_ID)
        .then(result => {
          context.commit('SET_CURRENT_BOT_DATA', result.data.data || null);
        });
    },
    updateProjectTrainingData(context, { project_id }) {
      axios.get(API_URL + '/local-intents-responses-storage/projects/knowledge-base?bot_id=' + API_BOT_ID + '&project_id=' + project_id)
        .then(result => {
          context.commit('SET_PROJECT_TRAINING_DATA', { project_id, data: result.data.data || '' });
        });
    },
  },
  modules: {
  }
})
