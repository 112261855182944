<template>
    <div class="form">
        <div v-if="typeSelect === 'table'">
            <table v-if="projectsList.length" class="table">
                <tr>
                    <th>
                        Projects
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr v-for="(project, index) of projectsList" :key="project.id">
                    <td>
                        <template v-if="editing?.[`${project.id}_name`]?.editing">
                            <div style="position: relative;">
                                <input style="background-color: white; max-width: 110px; font-size: larger;padding: 10px;border-radius: 10px;" v-model="editing[`${project.id}_name`].value" type="text" />
                                <button @click="editFieldEnd(`${project.id}_name`)" style="position: absolute;margin-top: 10px; left: 0;padding: 10px;background-color: var(--colAkcent);border-radius: 10px;color: white; cursor: pointer;margin-left: 0;">Cancel</button>
                                <button @click="saveEditProject(`${project.id}_name`)" style="position: absolute;margin-top: 10px;right: 0;padding: 10px;background-color: var(--colAkcent);border-radius: 10px; color: white; cursor: pointer;">Save</button>
                            </div>
                        </template>
                        <template v-else>
                            <div  @click="editFieldStart(`${project.id}_name`, project.name)" style="display: flex;">
                                {{ project.name }}<div style="margin-left: 20px;"></div>
                            </div>
                        </template>
                    </td>
                    <td style="width: 10px;">
                        <div v-if="!editing?.[`${project.id}_name`]?.editing"  @click="() => editFieldStart(`${project.id}_name`, project.name)">
                            <font-awesome-icon icon="edit" />
                        </div>
                    </td>
                    <td style="width: 10px;" @click="() => deleteProject(project.id)"><font-awesome-icon icon="trash"/></td>
                </tr>
            </table>
            <div style="text-align: center;" v-else>No projects added yet</div>
        </div>
        <select v-else type="text" v-model="value" placeholder="Select project">
            <option :disabled="!allowEmpty" :value="0">Select project</option>
            <option v-for="(project, index) of projectsList" :key="project.id" :value="project.id">{{ project.name }}
            </option>
        </select>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            value: this.modelValue,
            inteval: null,
            editing: {

            }
        }
    },
    props: ['modelValue', 'typeSelect','allowEmpty'],
    computed: {
        projectsList() {
            return this.$store.getters.getAvailableProjects;
        }
    },
    watch: {
        value() {
            this.$emit('update:modelValue', this.value);
        },
        projectsList() {
            if (this.allowEmpty || this.value || !this.projectsList.length) return;
            this.value = this.projectsList[0].id;
        }
    },
    methods: {
        editFieldStart(fieldName, initValue) {
            this.editing[fieldName] = {
                editing: true,
                value: initValue,
            };
        },
        editFieldEnd(fieldName) {
            this.editing[fieldName].editing = false;
        },
        async saveEditProject(fieldName) {
            const [projectId, projectFieldName] = fieldName.split('_');
            await this.updateProject(projectId, { [projectFieldName]: this.editing[fieldName].value });
            await this.updateList();
            this.editFieldEnd(fieldName);
        },
        async updateList() {
            try {
                this.$store.dispatch('updateAvailableProjects');
            } catch (error) {
                console.log(error);
            }
        },
        async deleteProject(id) {
            try {
                if (!window.confirm('Are you sure you want to delete the project?')) {
                    return;
                }
                axios.post(API_URL + '/local-intents-responses-storage/projects/delete', {
                    id, bot_id: API_BOT_ID
                }).then(() => {
                    this.$store.dispatch('updateAvailableProjects');
                })
            } catch (error) {
                console.log(error);
            }
        },
        async updateProject(id, data) {
            try {
                axios.post(API_URL + '/local-intents-responses-storage/projects/update', {
                    ...data,
                    id: +id, 
                    bot_id: API_BOT_ID,
                }).then(() => {
                    this.$store.dispatch('updateAvailableProjects');
                })
            } catch (error) {
                console.log(error);
            }
        }
    },
    created() {
        if (this.inteval) {
            clearInterval(this.inteval);
        }
        this.updateList()
        this.inteval = setInterval(() => this.updateList(), 10000);
    },
    beforeUnmount() {
        if (this.inteval) {
            clearInterval(this.inteval);
        }
    }

}
</script>

<style lang="scss">
div.form {

    // margin-bottom: 30px;

    select {
        flex-grow: 1;
        margin-right: 10px;
        background: var(--colBg);
        color: white;
        min-height: 30px;
        min-width: 100%;
        border-radius: 6px;
    }

}

table {
    min-width: 100%;

    td {
        padding: 20px;
    }

    td:last-child {
        max-width: 50px;
    }
}
</style>