<template>
  <template v-if="BACKGROUND">
    <video v-if="BACKGROUND.includes('.mp4')" autoplay muted loop id="backgroundVideo">
      <source :src="`/${BACKGROUND}`" type="video/mp4">
    </video>
    <img v-else :src="`/${BACKGROUND}`" id="backgroundVideo" />
  </template>
  <router-view />
</template>

<script>
export default {
  computed: {
    BACKGROUND() {
      if (this['BACKGROUND_' + this.$route?.name + '_VIDEO']) {
        return this['BACKGROUND_' + this.$route?.name + '_VIDEO'];
      }
      return this.BACKGROUND_VIDEO;
    }
  }
}
</script>

<style>
#backgroundVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-fit: cover;
}
</style>