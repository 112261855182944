import { createRouter, createWebHistory } from 'vue-router'
import Train from '../views/Train.vue'
import Chat from '../views/Chat.vue'
import Projects from '../views/Projects.vue'
import MainLayout from '../views/MainLayout.vue'

const routes = [
    {
        path: '',
        component: MainLayout,
        children: [
            {
                path: '/',
                name: 'home',
                component: Train
            },
            {
                path: '/chat',
                name: 'chat',
                component: Chat
            },
            {
                path: '/projects',
                name: 'projects',
                component: Projects
            },
        
        ],
        beforeEnter: (route, from, next) => {
            if (route?.name === undefined) {
                next({ name: 'home' });
            }
            next();
        }
    },
    {
        path: '/embed',
        children: [
            {
                name: 'embed',
                path: 'chat',
                component: Chat
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
